import React, { useEffect } from 'react';
import { Row, Col } from 'antd';

import { Card } from 'antd';
import Performance from './containers/Performance';
import styles from './styles.less';
import AccountInfo from './containers/AccountInfo';
import ExitAnalysis from '../Reports/ExitAnalysis';
import ProcessAnalysis from '../Reports/ProcessAnalysis';
import EntryTimeAnalysis from '../Reports/EntryTimeAnalysis';
import PerformanceChart from './Charts/Performance';
import AccumulativeProfitChart from './Charts/AccumulativeProfitChart';
// import API from '../../api';

const DashBoard = () =>

// useEffect(() => {
//    API.placeCoverOrder();
//    console.log("place api ordereeee\n\n\n")
// }, []);

 (
  <Row className={styles.dashboard} gutter={8}>

   <Col flex="2 1" style={{ 'marginBottom': '10px' }}>
    <Card size="small" style={{ 'marginBottom': '10px' }} title="Distribution of Gains and Losses"><PerformanceChart /></Card>
    <Card size="small" title="Accumulative Return">
     <AccumulativeProfitChart />
    </Card>
   </Col>

   <Col flex="1 1" style={{ 'marginBottom': '10px' }}>
    <EntryTimeAnalysis />
    <ExitAnalysis />

    {/* <ProcessAnalysis  /> */}

   </Col>
   <Col flex="1 1" >
    {/* <AccountInfo /> */}
    <Performance />

   </Col>
   {/* <Col
    span={5} style={{ 'marginLeft': '10px',
     'marginBottom': '10px' }}>

    <Card size="small" title="Accumulative Return">
     <AccumulativeProfitChart />
    </Card>
   </Col> */}

  </Row>
 );

export default DashBoard;
