import React from 'react';
import { useSelector } from 'react-redux';

import StandardTable from 'components/StandardTable';
import {
  getEntryTimeAnalysis
} from 'redux/modules/trading/selectors';

const EntryTimeAnalysis = () => {
  const timeAnalysis = useSelector(getEntryTimeAnalysis);

  const columns = [
    {
      'title': 'EntryTime',
      'dataIndex': 'time',
      'key': 'time'

    },
    {
      'title': 'Total',
      'dataIndex': 'total',
      'key': 'total'
    },
    {
      'title': 'Win',
      'dataIndex': 'win',
      'key': 'win'
    },
    {
      'title': 'Loss',
      'dataIndex': 'loss',
      'key': 'loss'
    },
    {
      'title': 'P/L',
      'dataIndex': 'netProfit',
      'key': 'netProfit'
    }
  ];

  return (
    <StandardTable columns={columns} pagination={false} dataSource={timeAnalysis} style={{ 'marginBottom': '10px' }}/>
  );
};

export default EntryTimeAnalysis;
