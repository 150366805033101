import types from './types';

export const addStrategy = strategy => ({
 'type': types.ADD_STRATEGY,
 strategy
});

export const addStock = stock => ({
 'type': types.ADD_STOCK_REQUEST,
 stock
});

export const getStocks = () => ({
 'type': types.GET_STOCKS_REQUEST
});

export const deleteStock = id => ({
 'type': types.DELETE_STOCK_REQUEST,
 id
});

export const getStocksSuccess = stocks => ({
 'type': types.GET_STOCKS_SUCCESS,
 stocks
});

export const syncCredentials = credentials => ({
 'type': types.SYNC_CREDENTIALS_SETTINGS,
 credentials
});

export const updateStock = stock => ({
 'type': types.UPDATE_STOCK_REQUEST,
 stock
});

export const editStock = stock => ({
 'type': types.EDIT_STOCK,
 stock
});
