import React, { useState, useEffect } from 'react';
import PageLoading from 'components/PageLoading';
// import cloudApi from 'service/cloudApi'
import { Redirect, useLocation } from 'react-router-dom';
import { createAccessToken } from 'redux/modules/trading/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
 isCredentialsLoaded, hasValidAccessToken
} from 'redux/modules/settings/selectors';

const AccessToken = () => {
 // const [istradingSession, setTradingSession] = useState(false)
 const hasTradingAccess = useSelector(hasValidAccessToken);
 const credentialsLoaded = useSelector(isCredentialsLoaded);

 const dispatch = useDispatch();

 const query = new URLSearchParams(useLocation().search);
 const status = query.get('status');
 const requestToken = query.get('request_token');

 useEffect(() => {
  async function generateTradingSession(requestToken) {
   // await cloudApi.generateAccessToken(requestToken);

   dispatch(createAccessToken(requestToken));

   // setTradingSession(true)
  }
  if (status === 'success' && credentialsLoaded) {
   generateTradingSession(requestToken);
  }
 }, [status, requestToken, credentialsLoaded, dispatch]);

 if (hasTradingAccess) {
  return <Redirect to="/trading-zone" />;
 }

 return <PageLoading />;
};

export default AccessToken;
