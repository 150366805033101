import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ZoneForm from './OptionsZoneForm';
import StandardTable from 'components/StandardTable';
import { getOrderMap, getTradingZoneMap, hasZoneWishList } from 'redux/modules/trading/selectors';
import { placeLimitOrder,
 deleteTradeZone,
 placeSLOrder,
 addZoneWishList,
 updateTradingZone
} from 'redux/modules/trading/actions';
import { FileAddOutlined, DeleteOutlined, HeartTwoTone, UnderlineOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
 Button, Checkbox, Modal, Popconfirm, Tabs
} from 'antd';
import ActiveOrders from './ActiveOrders';
import WishList from './WishList';
import { getOrder, buttonType } from './utils';
import TradingService from 'service/tradingService';

const { TabPane } = Tabs;

const TradingZone = () => {
 const zoneMap = useSelector(getTradingZoneMap);
 const orderMap = useSelector(getOrderMap);
 const [currentTrade, setCurrentTrade] = useState({});
 const [isModalVisible, setModalVisible] = useState(false);
 const hasWishList = useSelector(hasZoneWishList);

 const dispatch = useDispatch();

 const handleSave = () => {
  setCurrentTrade({});
  setModalVisible(false);
 };

 const editZone = (zone) => {
  setCurrentTrade(zone);
  setModalVisible(true);
 };

 const updateRider = (event, trade) => {
  dispatch(updateTradingZone({
   ...trade,
   'rider': event.target.checked
  }));
 };

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },
  {
   'title': 'Strike',
   'key': 'strike',
   //  'dataIndex': 'strike',
   'render': (zone) => (<span style={{ 'fontWeight': '500',
    'color': 'yellow' }}>
    <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
     {zone.strike}
    </a>

   </span>)
  },
  // {
  //  'title': 'Date',
  //  'key': 'date',
  //  'dataIndex': 'date'
  // //  'render': (text, row, index) => index + 1
  // },
  // {
  //  'title': 'Stock',
  //  // dataIndex: 'stock',
  //  'key': 'stock',
  //  // sortDirections: 'ascend',
  //  // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
  //  'defaultSortOrder': 'ascend',
  //  'render': (zone)=> (
  //   <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
  //    {zone.stock}
  //   </a>)
  // },

  // {
  //  'title': 'Action',
  //  'dataIndex': 'action',
  //  'key': 'action',
  //  'sorter': (a, b) => a.action.localeCompare(b.action)
  // },
  {
   'title': 'Quantity',
   'dataIndex': 'quantity',
   'key': 'quantity'
  },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price',
   'sorter': (a, b) => parseInt(a.price) - parseInt(b.price),
   'render': (strike) => (<span style={{ 'fontWeight': '500',
    'color': 'yellow' }}>{strike}</span>)
  },

  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  // {
  //  'title': 'Trailing SL',
  //  'dataIndex': 'trailingStopLoss',
  //  'key': 'trailingStopLoss'
  // },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  // {
  //  'title': 'Breakeven',
  //  'dataIndex': 'breakeven',
  //  'key': 'breakeven',
  //  'render': (breakeven) => <span style={{ 'fontWeight': '500' }}>{breakeven}</span>
  // },
  {
   'title': 'Risk/Lot',
   //  'dataIndex': 'risk',
   'key': 'risk',
   'render': (trade) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{trade.risk / (trade.quantity / trade.lotSize)}</span>),
   'align': 'center'
  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'Planned R:R',
   //  'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (trade) => <span style={{ 'fontWeight': '500' }}>1 : { isNaN((trade.reward / trade.risk).toFixed(2)) ? '' : (trade.reward / trade.risk).toFixed(2)}</span>
  },

  {
   'title': 'Status',
   'dataIndex': 'orderStatus',
   'key': 'orderStatus',
   'sorter': (a, b) => a && a.orderStatus && a.orderStatus.localeCompare(b.orderStatus),
   'defaultSortOrder': 'ascend'
  },
  {
   'title': 'Rider',
   'key': 'rider',
   'render': (trade)=> <Checkbox checked={trade.rider} onClick={(event) => updateRider(event, trade)} name="rider">Rider</Checkbox>
  },
  // {
  //  'title': 'Time Frame',
  //  'dataIndex': 'timeFrame',
  //  'key': 'timeFrame',
  //  'sorter': (a, b) => a && a.timeFrame && a.timeFrame.localeCompare(b.timeFrame),
  //  'defaultSortOrder': 'ascend'
  // },
  {
   'align': 'center',
   'render': (trade)=>
   //  trade.orderStatus === 'PENDING' &&
    // trade.orderStatus !== 'OPEN' &&
    (<>
     <Button style={{ 'fontWeight': '500' }} onClick={() => dispatch(placeLimitOrder(getOrder(trade)))} type={buttonType[trade.action]}>
      {trade.action}
     </Button>
     <Button
      style={{ 'fontWeight': '500',
       'marginLeft': 20 }} onClick={() => dispatch(placeSLOrder(getOrder(trade)))} type={buttonType[trade.action]}>
      {trade.action} SL
     </Button>
    </>)

  },
  {
   'align': 'center',
   'render': (trade)=>
   //  trade.orderStatus === 'PENDING' &&

    trade.orderStatus !== 'OPEN' &&
         <>
          <Button
           shape="circle"
           icon={<HeartTwoTone />}
           style={{ 'fontWeight': '500' }}
           onClick={() => dispatch(addZoneWishList(trade))} type="danger" />
         </>

  },
  {
   'render': (trade)=> (<Popconfirm
    title="Are you sure delete this trade zone"
    onConfirm={() => dispatch(deleteTradeZone(trade.id))}>
    <a><DeleteOutlined /></a>
   </Popconfirm>)
  }
 ];

 const operations = (<Button
  type="primary" onClick={() => {
   setModalVisible(true);
  }} icon={<FileAddOutlined />}>
            New Zone
 </Button>);

 const zoneTabs = [];

 for (const [stock, zones] of zoneMap.entries()) {
  zoneTabs.push(<TabPane
   tab={
    <span>
     {stock}
    </span>
   } key={stock}>
   <StandardTable columns={columns} dataSource={zones} style={{ 'marginBottom': '20px' }}/>
  </TabPane>);
 }

 const orderTabs = [];

 for (const [status, orders] of orderMap.entries()) {
  orderTabs.push(<TabPane
   tab={
    <span>
     <UnorderedListOutlined />
     {status}
    </span>
   } key={status}>
   <ActiveOrders orders={orders} />
  </TabPane>);
 }

 return (
  <>
   <Tabs tabBarExtraContent={operations}>
    {
     zoneTabs
    }
    {
     hasWishList &&
     <TabPane
      tab={
       <span>
        Wish List
       </span>
      } key="wishList">
      <WishList />
     </TabPane>
    }

    {
     orderTabs
    }

   </Tabs>

   <Modal
    destroyOnClose
    maskClosable={false}
    width={915}
    footer={null}
    onCancel={() => {
     setModalVisible(false); setCurrentTrade({});
    }}
    title="Trading Zone"
    visible={isModalVisible}>
    <ZoneForm optionType="" trade={currentTrade} key={currentTrade.id} handleSave={handleSave}/>
   </Modal>
  </>
 );
};

export default TradingZone;
