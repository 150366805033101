import types from './types';

export default function reducer(state = {}, action = {}) {
 switch (action.type) {
  case types.SYNC_TRADES:
   return {
    ...state,
    'trades': [...action.trades]
   };
  case types.GET_STRATEGIES_SUCCESS:
   return {
    ...state,
    'strategies': [...action.strategies]
   };
  case types.GET_TRADING_ZONES_SUCCESS:
   return {
    ...state,
    'tradingZones': [...action.tradingZones]
   };
  case types.GET_ALL_TRADING_ZONES_SUCCESS:
   console.log('GET_ALL_TRADING_ZONES_SUCCESS', action.tradingZones);

   return {
    ...state,
    'allTradingZones': [...action.tradingZones]
   };
  case types.SYNC_ORDER_SUCCESS:
   return {
    ...state,
    'orders': [...action.orders]
   };
  case types.SYNC_ZONE_WISHLIST_SUCCESS:
   return {
    ...state,
    'zoneWishList': [...action.zoneWishList]
   };

  case types.SYNC_PENDING_ORDERS_SUCCESS:
   return {
    ...state,
    'pendingOrders': [...action.pendingOrders]
   };

  default:
   return state;
 }
}
