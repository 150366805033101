import React from 'react';
import { Switch, Route as ReactRoute } from 'react-router-dom';
import Route from './Route';

import SignIn from 'pages/SignIn';

import Dashboard from 'pages/Dashboard';
import Settings from 'pages/Settings';
import TrdeLog from 'pages/Trades/NewTrade';
import TradingProcess from 'pages/Trades/Process';
import Strategies from 'pages/Strategies';
import ClosedTrades from 'pages/Trades/ClosedTrades';
import Exception403 from 'pages/Exception/403';
import Banking from 'pages/Banking';
import TradingZone from 'pages/TradingZone';
import AccessToken from '../pages/AccessToken';
import Stocks from '../pages/Admin/Stocks';
import AllTradingZones from 'pages/AllTradingZones';
import Trades from 'pages/TradingZone/Trades';

export default function Routes() {
 return (
  <Switch>
   <Route path="/" exact component={SignIn} />
   <Route path="/trade-entry" component={TrdeLog} isPrivate/>
   <Route path="/trades" component={Trades} isPrivate/>

   <Route path="/dashboard" component={Dashboard} isPrivate />
   <Route path="/settings" component={Settings} isPrivate/>
   <Route path="/strategies" component={Strategies} isPrivate/>
   <Route path="/trading-process" component={TradingProcess} isPrivate/>
   <Route path="/banking" component={Banking} isPrivate/>
   <Route path="/trading-journal" component={ClosedTrades} isPrivate/>
   <Route path="/trading-zone" component={TradingZone} isPrivate isTrading/>
   <Route path="/access-token" component={AccessToken} isPrivate/>
   <Route path="/admin/stocks" component={Stocks} isPrivate/>
   <Route path="/all-zones" component={AllTradingZones} isPrivate isTrading/>

   <ReactRoute path="/403" component={Exception403}/>

   {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
   <Route component={SignIn} />
  </Switch>
 );
}
