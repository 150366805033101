import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { addStock, updateStock } from 'redux/modules/settings/actions';
import { getStocks, getInstruments } from 'redux/modules/admin/selectors';
import { getSelectedStock } from 'redux/modules/settings/selectors';

const { Option } = Select;

const basicRules = [{ 'required': true,
  'message': 'Please input a value' }];

const StockForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const stocks = useSelector(getStocks);
  const instruments = useSelector(getInstruments);
  const stock = useSelector(getSelectedStock);

  console.log('selected stock---', stock);

  useEffect(() => {
    form.resetFields();
  }, [form, stock]);

  const saveStock = values => {
    if (stock.id) {
      dispatch(updateStock({
        ...stock,
        ...values,
        'instrumentToken': instruments[form.getFieldValue('name')]
      }));
    } else {
      dispatch(addStock({
        ...values,
        'instrumentToken': instruments[form.getFieldValue('name')]
      }));
    }

    form.resetFields();
  };

  return (
    <Form
      key={stock.id || '_form'}
      form={form}
      layout="vertical"
      name="stockFoem"
      hideRequiredMark
      onFinish={saveStock}
      initialValues={{
        ...stock
      }}
      style={{
        'padding': '24px',
        // background: '#fff',
        'border': '1px solid #d9d9d9',
        'marginBottom': '20px'
      }}>
      <Row gutter={10} >
        <Col span={5} >

          <Form.Item
            name="name"
            rules={basicRules}
            label="Stock Name">
            <Select style={{ 'width': '200px' }}>
              {stocks.map(item => (
                <Option key={item.name}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            rules={basicRules}
            name="quantity"
            label="Quantity">
            <Input autoComplete="off"/>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item
            rules={basicRules}
            name="trailingStopLoss"
            label="Trailing Stop Loss">
            <Input autoComplete="off"/>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item
            rules={basicRules}
            name="targetBreak"
            label="Target Break">
            <Input autoComplete="off"/>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={14} style={{ 'textAlign': 'right' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default StockForm;
