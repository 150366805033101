import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from 'pages/layouts/DefaultLayout';
import AuthLayout from 'pages/layouts/AuthLayout';
import PageLoading from 'components/PageLoading';
import useAuth from 'hooks/useAuth';
import { redirect } from 'helpers/routing';
import tradingService from '../service/tradingService';
import { useSelector } from 'react-redux';
import {
 hasValidAccessToken, isCredentialsLoaded, getLoginUrl
} from 'redux/modules/settings/selectors';

export default function RouteWrapper({
 'component': Component,
 isPrivate,
 isTrading,
 path,
 ...rest
}) {
 const { 'session': { isSignedIn, loading, isApproved } } = useAuth();
 const hasTradingAccess = useSelector(hasValidAccessToken);
 // const credentials = useSelector(getCredentials);
 const hasCredentials = useSelector(isCredentialsLoaded);
 const loginUrl = useSelector(getLoginUrl);

 console.log('credentials---------', loading, hasCredentials, hasTradingAccess);

 if (loading) {
  return <PageLoading />;
 }

 /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
 if (isPrivate && !isSignedIn) {
  return <Redirect to="/" />;
 }

 console.log('isPrivate---------', isPrivate, !isSignedIn, path);

 /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
 if (!isPrivate && isSignedIn) {
  return <Redirect to="/dashboard" />;
 }

 if (isPrivate && !isApproved) {
  return <Redirect to="/403" />;
 }

 const Layout = isSignedIn ? AuthLayout : DefaultLayout;

 if (isTrading) {
  if (!hasCredentials) {
   return <PageLoading />;
  }
  if (!hasTradingAccess) {
   redirect(loginUrl);
  }
 }

 /**
   * If not included on both previous cases, redirect user to the desired route.
   */
 return (
  <Route
   {...rest}
   render={props => (
    <Layout>
     <Component {...props} />
    </Layout>
   )}/>
 );
}

RouteWrapper.propTypes = {
 'isPrivate': PropTypes.bool,
 'component': PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
 'isPrivate': false
};
