export default ['Broadening Bottoms',
 'Broadening Tops',
 'Bump-and-Run Reversal Bottoms',
 'Bump-and-Run Reversal Tops',
 'Cup and Handle',
 'Cup and Handle (Inverted)',
 'Diamond Bottoms', 'Diamond Tops',
 'Double Bottom (Adam & Adam)',
 'Double Bottom (Adam & Eve)',
 'Double Bottom (Eve & Eve)',
 'Double Tops (Adam & Adam)',
 'Double Tops (Adam & Eve)',
 'Double Tops (Eve & Eve)',
 'Flags (Bullish)',
 'Flags (Bearish)',
 'Flags (High & Tight)',
 'Gaps (Area)', 'Gaps (Breakaway)',
 'Gaps (Continuation)',
 'Gaps (Exhaustion)',
 'Head & Shoulders (Inverted)',
 'Head & Shoulders (Inverted Complex)',
 'Head & Shoulders (Inverted Continuation)',
 'Head & Shoulders',
 'Head & Shoulders (Complex)',
 'Horn Bottoms', 'Horn Tops',
 'Island Reversals',
 'Pennants (Bullish)',
 'Pennants (Bearish)',
 'Pipe Bottoms', 'Pipe Tops',
 'Rectangle Bottoms',
 'Rectangle Tops',
 'Rounding Bottoms', 'Rounding Tops',
 'Scallops (Ascending)',
 'Scallops (Inverted Ascending)',
 'Scallops (Descending)',
 'Scallops (Inverted Descending)',
 'Three Falling Peaks',
 'Three Rising Valleys',
 'Triangles (Ascending)',
 'Triangles (Descending)',
 'Triangles (Symmetrical)',
 'Triple Bottoms', 'Triple Tops',
 'Wedges (Falling)',
 'Wedges (Rising)'
];
