import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Layout, Menu, BackTop } from 'antd';
import {
 SettingOutlined,
 DashboardOutlined,
 BankOutlined,
 AuditOutlined,
 LaptopOutlined,
 BarsOutlined
} from '@ant-design/icons';
import styles from './layout.less';

const { Header, Content, Footer } = Layout;
// const { SubMenu } = Menu;

export default function AuthLayout({ children }) {
 return (
  <Layout style={{ 'minHeight': '100vh' }} id="mainContainer">
   <Layout className={styles.authLayout}>
    <BackTop target={() => document.getElementById('mainContainer')} />

    {/* <Header className={styles.background} style={{ padding: 0 }} > */}

    {/* <Menu mode="horizontal">
              <SubMenu icon={<MenuFoldOutlined />}>
                <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                  <Link to="/dashboard">
                      Dashboard
                  </Link>
                </Menu.Item>

              <Menu.Item key="tradeLog" icon={<AccountBookOutlined />}>
                <Link to="/trade-log">
                  Trade Log
                </Link>
              </Menu.Item>
              <Menu.Item key="reports" icon={<AccountBookOutlined />}>
                <Link to="/reports">
                  Reports
                </Link>
              </Menu.Item>
              <Menu.Item key="bankTransfers" icon={<BankOutlined />}>
                <Link to="/bank-transfers">
                  Bank Transfers
                </Link>
                </Menu.Item>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                <Link to="/settings">
                  Settings
                </Link>
                </Menu.Item>
            </SubMenu>
          </Menu> */}
    {/* </Header> */}
    <Menu mode="horizontal">
     <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
      <Link to="/dashboard">
         Dashboard
      </Link>
     </Menu.Item>
     <Menu.Item key="trading-zone" icon={<LaptopOutlined />}>
      <Link to="/trades">
          Trades
      </Link>
     </Menu.Item>
     {/*
     <Menu.Item key="trading-zone" icon={<LaptopOutlined />}>
      <Link to="/trading-zone">
          Trading Zone
      </Link>
     </Menu.Item>
     <Menu.Item key="all-trading-zone" icon={<LaptopOutlined />}>
      <Link to="/all-zones">
        All Zones
      </Link>
     </Menu.Item> */}
     {/* <Menu.Item key="tradeLog" icon={<AccountBookOutlined />}>
                <Link to="/trade-entry">
                  Trade Entry
                </Link>
              </Menu.Item>*/}
     {/* <Menu.Item key="tradingJournal" icon={<AuditOutlined />}>
      <Link to="/trading-journal">
                  Trading Journal
      </Link>
     </Menu.Item>

     <Menu.Item key="bankTransfers" icon={<BankOutlined />}>
      <Link to="/banking">
                  Bank Transfers
      </Link>
     </Menu.Item> */}

     {/* <Menu.Item key="settings" icon={<SettingOutlined />}>
      <Link to="/settings">
                  Settings
      </Link>
     </Menu.Item>

     <Menu.Item key="admin_stocks" icon={<BarsOutlined />}>
      <Link to="/admin/stocks">
                  Stocks
      </Link>
     </Menu.Item> */}
     {/*
                <Menu.Item key="reports" icon={<AccountBookOutlined />}>
                <Link to="/reports">
                  Reports
                </Link>
                </Menu.Item>

                <Menu.Item key="bankTransfers" icon={<BankOutlined />}>
                <Link to="/bank-transfers">
                  Bank Transfers
                </Link>
              </Menu.Item> */}
    </Menu>
    <Content className={styles.content}>
     {children}
    </Content>
    {/* <Footer style={{ textAlign: 'center' }}>Technical Analysis</Footer> */}
   </Layout>
  </Layout>
 );
}

AuthLayout.propTypes = {
 'children': PropTypes.element.isRequired
};

