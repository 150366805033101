import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Progress, Card, Statistic, Divider } from 'antd';
import { getWinRate,
  getWinTradesCount,
  getLossTradesCount,
  getWinTrades,
  getTotalGain,
  getTotalLoss,
  getNetProfit,
  getMaxProfit,
  getMaxLoss,

  getExitAnalysis
} from 'redux/modules/trading/selectors';
// import CardInfo from '../../../components/CardInfo';
// import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const Performance = () => {
  const winRate = useSelector(getWinRate);
  const win = useSelector(getWinTradesCount);
  const loss = useSelector(getLossTradesCount);

  const totalGain = useSelector(getTotalGain);
  const totalLoss = useSelector(getTotalLoss);
  const netProfit = useSelector(getNetProfit);
  const maxProfit = useSelector(getMaxProfit);
  const maxLoss = useSelector(getMaxLoss);

  return (

    <Card size="small" title="Overall Performance" style={{ 'marginBottom': '10px' }}>

      <Row justify="space-between">
        <Col >
          <Statistic
            title="Total"
            value={win + loss}/>

        </Col>
        <Col>
          <Statistic
            title="Win"
            value={win}
            // precision={2}
            valueStyle={{ 'color': '#3f8600' }}/>

        </Col>
        <Col>
          <Statistic
            title="Loss"
            value={loss}
            // precision={2}
            valueStyle={{ 'color': '#cf1322' }}/>

        </Col>
        <Col>
          <Statistic
            title="Success Rate"
            value={winRate}
            precision={2}
            valueStyle={{ 'color': '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
            suffix="%"/>

        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" >

        <Col>
          <Statistic
            title="Gain"
            value={totalGain}
            precision={2}
            valueStyle={{ 'color': '#3f8600' }}/>

        </Col>
        <Col style={{ 'marginLeft': '20px',
          'marginRight': '20px' }}>
          <Statistic
            title="Loss"
            value={totalLoss}
            precision={2}
            valueStyle={{ 'color': '#cf1322' }}/>

        </Col>
        <Col>
          <Statistic
            title="Net Profit"
            value={netProfit}
            precision={2}
            valueStyle={{ 'color': netProfit < 0 ? '#cf1322' : '#3f8600' }}/>

        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" >

        <Col>
          <Statistic
            title="Largest Profit"
            value={maxProfit}
            precision={2}
            valueStyle={{ 'color': '#3f8600' }}/>

        </Col>
        <Col>
          <Statistic
            title="Larget Loss"
            value={maxLoss}
            precision={2}
            valueStyle={{ 'color': '#cf1322' }}/>

        </Col>
      </Row>

    </Card>);
};

export default Performance;
