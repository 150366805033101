import types from './types';

export default function reducer(state = {}, action = {}) {
 switch (action.type) {
  case types.GET_STOCKS_SUCCESS:
   return {
    ...state,
    'stocks': action.stocks,
    'selectedStock': {}
   };
  case types.EDIT_STOCK:
   console.log('edit stock');

   return {
    ...state,
    'selectedStock': action.stock
   };
  case types.SYNC_CREDENTIALS_SETTINGS:

   return {
    ...state,
    'credentials': { ...action.credentials },
    'credentialsLoaded': true
   };
  default:
   return state;
 }
}
