import React from 'react';
import { Result } from 'antd';

export default () => (
  <Result
    status="403"
    style={{
      'background': 'none'
    }}
    subTitle="Sorry, you don't have access to this page."/>
);
