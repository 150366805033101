import React, { useState, useEffect } from 'react';

import { Form, Row, Col, Input, Button, Select, Tag } from 'antd';
import { getClosedPosition } from 'utils/brokerage';

import styles from './styles.less';
import stocks from 'constants/stocks';
import chartPatterns from 'constants/chartPatterns';

const { Option } = Select;

const basicRules = [{ 'required': true,
 'message': 'Please input a value' }];

const TradeForm = ({ order = {}, handleSave }) => {
 const [form] = Form.useForm();
 const [risk, setRisk] = useState(order.risk || 0);
 const [reward, setReward] = useState(order.reward || 0);
 const [quantity, setQuantity] = useState(0);

 const handleStockChange = (name) => {
  const currentStock = stocks.find(obj => obj.tradingsymbol === name) || {};

  setQuantity(currentStock.quantity);
 };

 const onFinish = values => {
  console.log('onFinish', {
   ...values,
   risk,
   reward
  });
  //   if (order.id) {
  //    dispatch(updateTradingZone({
  //     ...order,
  //     ...values,
  //     action,
  //     'id': order.id,
  //     risk,
  //     reward,
  //     'breakeven': getBreakeven(),
  //     trailingStopLoss,
  //     targetBreak,
  //     'orderStatus': 'PENDING',
  //     strike,
  //     'lotSize': 25

  //    }));
  //   } else {
  //    dispatch(addTradingZone({
  //     ...values,
  //     action,
  //     // date:  moment().format('YYYYMMDD'),
  //     risk,
  //     reward,
  //     'breakeven': getBreakeven(),
  //     'orderStatus': 'PENDING',
  //     trailingStopLoss,
  //     targetBreak,
  //     strike,
  //     'lotSize': 25
  //    }));
  //   }
  const _order = {
   'status': 'OPEN',
   ...order,
   ...values,
   risk,
   reward
  };

  if (order.id) {
   _order.id = order.id;
  }

  handleSave(_order);

  form.resetFields();
 };

 const handleRisk = () => {
  const price = parseFloat(form.getFieldValue('price') || 0);
  const stopLoss = form.getFieldValue('stopLoss') || 0;
  const target = parseFloat(form.getFieldValue('target') || 0);

  console.log('price - stopLoss', price, stopLoss, quantity);

  const risk = ((Math.abs(price - stopLoss) * quantity));
  // const riskPercentage = (risk / accountBalance) * 100;
  const reward = ((Math.abs(target - price) * quantity)); // - breakeven;

  setRisk(risk.toFixed(2));
  setReward(reward);
 };

 return (
  <Form
   form={form}
   layout="vertical"
   name="advanced_search"
   onFinish={onFinish}
   hideRequiredMark
   className={styles.orderForm}
   initialValues={{
    ...order
   }}
   style={{
    'padding': '24px',
    // background: '#fff',
    'border': '1px solid #d9d9d9',
    'marginBottom': '20px'
    // width: '90%'
   }}>
   <Row gutter={24}>
    <Col>
     <Form.Item
      rules={basicRules}
      name="stock"
      label="Stock">
      <Select defaultValue="" showSearch onChange={handleStockChange} style={{ 'width': '110px' }}>
       {stocks.map(item => (
        <Option key={item.tradingsymbol} value={item.tradingsymbol}>{item.name}</Option>
       ))}
      </Select>
     </Form.Item>
    </Col>

    <Col>
     <Form.Item
      rules={basicRules}
      style={{ 'width': '220px' }}
      name="chartImage"
      label="Chart Image">
      <Input autoComplete="off"/>
     </Form.Item>
    </Col>

    <Col>
     <Form.Item
      rules={basicRules}
      name="pattern"
      label="Chart Pattern">
      <Select defaultValue="" showSearch style={{ 'width': '220px' }}>
       {chartPatterns.map(item => (
        <Option key={item} value={item}>{item}</Option>
       ))}
      </Select>
     </Form.Item>
    </Col>

   </Row>

   <Row gutter={24}>
    <Col>
     <Form.Item
      rules={basicRules}
      name="trend"
      label="Trend">
      <Select defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="up" value="Up">Up</Option>
       <Option key="down" value="Down">Down</Option>
      </Select>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      name="action"
      label="Order Type">
      <Select defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="buy" value="BUY">BUY</Option>
       <Option key="sell" value="SELL">SELL</Option>
      </Select>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      style={{ 'width': '70px' }}
      name="price"
      label="Entry Price">
      <Input autoComplete="off" onChange={handleRisk} />
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      style={{ 'width': '80px' }}
      name="stopLoss"
      label="Stop Loss">
      <Input autoComplete="off" onChange={handleRisk}/>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      style={{ 'width': '70px' }}
      name="target"
      label="Target">
      <Input autoComplete="off" onChange={handleRisk}/>

     </Form.Item>
    </Col>

    <Col>
     <Form.Item
      style={{ 'alignItems': 'centre' }}
      label="Planned R:R">
      <Tag
       color="green" style={{ 'fontWeight': 'bold',
        'marginLeft': '20px' }}>{`1: ${isNaN((reward / risk).toFixed(2)) ? '' : (reward / risk).toFixed(2)}`}</Tag>
     </Form.Item>
    </Col>

   </Row>
   <Row justify="end">
    <Col>
     <Button type="primary" htmlType="submit">
            Save
     </Button>
    </Col>
   </Row>
  </Form>
 );
};

export default TradeForm;
