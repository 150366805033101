export default [
 {
  'instrument_token': 260105,
  'tradingsymbol': 'BANKNIFTY',
  'name': 'BANKNIFTY',
  'trailingStopLoss': 0,
  'quantity': 25,
  'exchange': 'NFO',
  'label': 'BANKNIFTY',
  'value': 'BANKNIFTY'
 },
 {
  'instrument_token': 256265,
  'tradingsymbol': 'NIFTY50',
  'name': 'NIFTY50',
  'trailingStopLoss': 0,
  'quantity': 50,
  'exchange': 'NFO',
  'label': 'NIFTY50',
  'value': 'NIFTY50'
 }
];
