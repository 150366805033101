export const getOrder = (trade) => {
 const order = {
  'quantity': trade.quantity,
  'tradingsymbol': trade.stock,
  'transaction_type': trade.action,
  'price': trade.price,
  'tradingZoneId': trade.id,
  'breakeven': trade.breakeven,
  'stopLoss': trade.stopLoss,
  'trailingStopLoss': trade.trailingStopLoss,
  'target': trade.target,
  'risk': trade.risk,
  // 'riskReward': trade.riskReward,
  // 'riskPercentage': trade.riskPercentage,
  'targetBreak': trade.targetBreak,
  'strike': trade.strike,
  'optionType': trade.optionType
 };

 if (trade.rider) {
  order.rider = trade.rider;
 }

 return order;
};

export const buttonType = {
 'SELL': 'danger',
 'BUY': 'primary'
};
