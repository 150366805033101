import React from 'react';
import { Table } from 'antd';
import styles from './standardTable.less';

const StandardTable = ({
 dataSource,
 columns,
 rowKey,
 footer,
 expandedRowRender,
 pagination,
 defaultExpandAllRows,
 ...rest
}) => (
 <div className={styles.standardTable}>

  <Table
   key="standard-table"
   {...rest}
   // pagination={{ pageSize: 100 }}
   rowClassName={(_, index) => (index % 2 ? styles.odd : '')}
   rowKey={rowKey || 'key'}
   bordered
   pagination={false}
   footer={footer}
   // scroll={{ y: 500 }}
   expandedRowRender={expandedRowRender}
   defaultExpandAllRows={defaultExpandAllRows}
   dataSource={dataSource}
   columns={columns} />
 </div>
);

export default StandardTable;
