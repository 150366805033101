import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import TradeForm from './TradeForm';
import StandardTable from 'components/StandardTable';
import { placeLimitOrder } from 'redux/modules/trading/actions';
import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import {
 Button, Modal, Popconfirm
} from 'antd';
import { getOrder, buttonType } from './utils';
import useTrades from './hooks/useTrades';
import { database } from 'service/firebase';

const Trades = () => {
 const [currentTrade, setCurrentTrade] = useState({});
 const [isModalVisible, setModalVisible] = useState(false);
 const orders = useTrades();

 const dispatch = useDispatch();

 const handleSave = (order) => {
  try {
   if (order.id) {
    database
     .ref(`/orders/${order.id}`)
     .update({
      ...order,
      'date': moment().format('YYYY-MM-DD hh:mm a')
     })
     .then(() => console.log('Data updated.'));
   } else {
    const newReference = database
     .ref('/orders')
     .push();

    newReference
     .set({
      'id': newReference.key,
      ...order,
      'date': moment().format('YYYY-MM-DD hh:mm a')
     })
     .then(() => console.log('Data updated.'));
   }
  } catch (error) {
   console.log('tradingZone error', error);
  }

  setCurrentTrade({});
  setModalVisible(false);
 };

 const editZone = (zone) => {
  setCurrentTrade(zone);
  setModalVisible(true);
 };

 const handleDelete = (orderId)=> {
  database
   .ref(`/orders/${orderId}`)
   .remove();
 };

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },
  // {
  //  'title': 'Strike',
  //  'key': 'strike',
  //  //  'dataIndex': 'strike',
  //  'render': (zone) => (<span style={{ 'fontWeight': '500',
  //   'color': 'yellow' }}>
  //   <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
  //    {zone.strike}
  //   </a>

  //  </span>)
  // },

  {
   'title': 'Stock',
   // dataIndex: 'stock',
   'key': 'stock',
   // sortDirections: 'ascend',
   // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
   'defaultSortOrder': 'ascend',
   'render': (zone)=> (
    <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
     {zone.stock}
    </a>)
  },
  {
   'title': 'Date',
   'key': 'date',
   'dataIndex': 'date'
  },
  {
   'title': 'Action',
   'dataIndex': 'action',
   'key': 'action',
   'sorter': (a, b) => a.action.localeCompare(b.action)
  },
  // {
  //  'title': 'Quantity',
  //  'dataIndex': 'quantity',
  //  'key': 'quantity'
  // },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price',
   'sorter': (a, b) => parseInt(a.price) - parseInt(b.price),
   'render': (strike) => (<span style={{ 'fontWeight': '500'
   // 'color': 'yellow'
   }}>{strike}</span>)
  },

  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  {
   'title': 'Planned R:R',
   'key': 'riskReward',
   'align': 'center',
   'render': (trade) => <span style={{ 'fontWeight': '500' }}>1 : { isNaN((trade.reward / trade.risk).toFixed(2)) ? '' : (trade.reward / trade.risk).toFixed(2)}</span>
  },
  {
   'align': 'center',
   'render': (trade)=>
   //  trade.orderStatus === 'PENDING' &&
    // trade.orderStatus !== 'OPEN' &&
    (<>
     <Button style={{ 'fontWeight': '500' }} onClick={() => dispatch(placeLimitOrder(getOrder(trade)))} type={buttonType[trade.action]}>
      {trade.action}
     </Button>

    </>)

  },

  {
   'render': (trade)=> (<Popconfirm
    title="Are you sure delete this order"
    onConfirm={() => handleDelete(trade.id)}>
    <a><DeleteOutlined /></a>
   </Popconfirm>)
  }
 ];

 return (
  <>
   <div style={{ 'display': 'flex',
    'justifyContent': 'flex-end',
    'marginBottom': 10 }}>
    <Button
     type="primary" onClick={() => {
      setModalVisible(true);
     }} icon={<FileAddOutlined />}>
            New Trade
    </Button>
   </div>
   <StandardTable columns={columns} dataSource={orders} style={{ 'marginBottom': '20px' }}/>

   <Modal
    destroyOnClose
    maskClosable={false}
    width={760}
    footer={null}
    onCancel={() => {
     setModalVisible(false); setCurrentTrade({});
    }}
    title="Trade Form"
    visible={isModalVisible}>
    <TradeForm optionType="" order={currentTrade} key={currentTrade.id} handleSave={handleSave}/>
   </Modal>
  </>
 );
};

export default Trades;
