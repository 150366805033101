
import { useEffect, useState } from 'react';
import { database } from 'service/firebase';
import objectToArray from 'utils/objectToArray';

const useTrades = () => {
 const [orders, setOrders] = useState([]);

 useEffect(() => {
  try {
   database
    .ref('/orders')
    .on('value', snapshot => {
     setOrders(objectToArray(snapshot.val()));
    });
  } catch (error) {
   // eslint-disable-next-line no-console
   console.log('Error', error);
  }
 }, []);

 return orders;
};

export default useTrades;
