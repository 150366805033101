import React from 'react';
import { useSelector } from 'react-redux';
import StandardTable from 'components/StandardTable';
import {
  getExitAnalysis
} from 'redux/modules/trading/selectors';

const ExitAnalysis = () => {
  const exitAnalysis = useSelector(getExitAnalysis);

  const columns = [
    {
      'title': 'Exit Reason',
      'dataIndex': 'exitReason',
      'key': 'exitReason'
    },
    {
      'title': 'Total',
      'dataIndex': 'total',
      'key': 'total'
    },
    {
      'title': 'Win',
      'dataIndex': 'win',
      'key': 'win'
    },
    {
      'title': 'Loss',
      'dataIndex': 'loss',
      'key': 'loss'
    }
  ];

  return (
    <StandardTable columns={columns} pagination={false} dataSource={exitAnalysis} style={{ 'marginBottom': '10px' }}/>
  );
};

export default ExitAnalysis;
