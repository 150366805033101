export default [
 {
  'instrument_token': 12608514,
  'exchange_token': 49252,
  'tradingsymbol': 'NIFTY2160312800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12608770,
  'exchange_token': 49253,
  'tradingsymbol': 'NIFTY2160312800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12609026,
  'exchange_token': 49254,
  'tradingsymbol': 'NIFTY2160312850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12609282,
  'exchange_token': 49255,
  'tradingsymbol': 'NIFTY2160312850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12609538,
  'exchange_token': 49256,
  'tradingsymbol': 'NIFTY2160312900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12609794,
  'exchange_token': 49257,
  'tradingsymbol': 'NIFTY2160312900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12610050,
  'exchange_token': 49258,
  'tradingsymbol': 'NIFTY2160312950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12610306,
  'exchange_token': 49259,
  'tradingsymbol': 'NIFTY2160312950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 12950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12610562,
  'exchange_token': 49260,
  'tradingsymbol': 'NIFTY2160313000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12610818,
  'exchange_token': 49261,
  'tradingsymbol': 'NIFTY2160313000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12611074,
  'exchange_token': 49262,
  'tradingsymbol': 'NIFTY2160313050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12611330,
  'exchange_token': 49263,
  'tradingsymbol': 'NIFTY2160313050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12611586,
  'exchange_token': 49264,
  'tradingsymbol': 'NIFTY2160313100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12611842,
  'exchange_token': 49265,
  'tradingsymbol': 'NIFTY2160313100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10151938,
  'exchange_token': 39656,
  'tradingsymbol': 'NIFTY2160313150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10152194,
  'exchange_token': 39657,
  'tradingsymbol': 'NIFTY2160313150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10153474,
  'exchange_token': 39662,
  'tradingsymbol': 'NIFTY2160313200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10153730,
  'exchange_token': 39663,
  'tradingsymbol': 'NIFTY2160313200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10155522,
  'exchange_token': 39670,
  'tradingsymbol': 'NIFTY2160313250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10155778,
  'exchange_token': 39671,
  'tradingsymbol': 'NIFTY2160313250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10156546,
  'exchange_token': 39674,
  'tradingsymbol': 'NIFTY2160313300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10156802,
  'exchange_token': 39675,
  'tradingsymbol': 'NIFTY2160313300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10506242,
  'exchange_token': 41040,
  'tradingsymbol': 'NIFTY2160313350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10506498,
  'exchange_token': 41041,
  'tradingsymbol': 'NIFTY2160313350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10506754,
  'exchange_token': 41042,
  'tradingsymbol': 'NIFTY2160313400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10507010,
  'exchange_token': 41043,
  'tradingsymbol': 'NIFTY2160313400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10507266,
  'exchange_token': 41044,
  'tradingsymbol': 'NIFTY2160313450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10507522,
  'exchange_token': 41045,
  'tradingsymbol': 'NIFTY2160313450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10507778,
  'exchange_token': 41046,
  'tradingsymbol': 'NIFTY2160313500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10508034,
  'exchange_token': 41047,
  'tradingsymbol': 'NIFTY2160313500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10508290,
  'exchange_token': 41048,
  'tradingsymbol': 'NIFTY2160313550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10508546,
  'exchange_token': 41049,
  'tradingsymbol': 'NIFTY2160313550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10508802,
  'exchange_token': 41050,
  'tradingsymbol': 'NIFTY2160313600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10509058,
  'exchange_token': 41051,
  'tradingsymbol': 'NIFTY2160313600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10509314,
  'exchange_token': 41052,
  'tradingsymbol': 'NIFTY2160313650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10509570,
  'exchange_token': 41053,
  'tradingsymbol': 'NIFTY2160313650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10509826,
  'exchange_token': 41054,
  'tradingsymbol': 'NIFTY2160313700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10510082,
  'exchange_token': 41055,
  'tradingsymbol': 'NIFTY2160313700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10510850,
  'exchange_token': 41058,
  'tradingsymbol': 'NIFTY2160313750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10511106,
  'exchange_token': 41059,
  'tradingsymbol': 'NIFTY2160313750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10511362,
  'exchange_token': 41060,
  'tradingsymbol': 'NIFTY2160313800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10511618,
  'exchange_token': 41061,
  'tradingsymbol': 'NIFTY2160313800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10512386,
  'exchange_token': 41064,
  'tradingsymbol': 'NIFTY2160313850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10512642,
  'exchange_token': 41065,
  'tradingsymbol': 'NIFTY2160313850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10512898,
  'exchange_token': 41066,
  'tradingsymbol': 'NIFTY2160313900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10513154,
  'exchange_token': 41067,
  'tradingsymbol': 'NIFTY2160313900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10513410,
  'exchange_token': 41068,
  'tradingsymbol': 'NIFTY2160313950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10514178,
  'exchange_token': 41071,
  'tradingsymbol': 'NIFTY2160313950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 13950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10514434,
  'exchange_token': 41072,
  'tradingsymbol': 'NIFTY2160314000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10514690,
  'exchange_token': 41073,
  'tradingsymbol': 'NIFTY2160314000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10516482,
  'exchange_token': 41080,
  'tradingsymbol': 'NIFTY2160314050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10516738,
  'exchange_token': 41081,
  'tradingsymbol': 'NIFTY2160314050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10516994,
  'exchange_token': 41082,
  'tradingsymbol': 'NIFTY2160314100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10518018,
  'exchange_token': 41086,
  'tradingsymbol': 'NIFTY2160314100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10518274,
  'exchange_token': 41087,
  'tradingsymbol': 'NIFTY2160314150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10518786,
  'exchange_token': 41089,
  'tradingsymbol': 'NIFTY2160314150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10523906,
  'exchange_token': 41109,
  'tradingsymbol': 'NIFTY2160314200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10524162,
  'exchange_token': 41110,
  'tradingsymbol': 'NIFTY2160314200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10524418,
  'exchange_token': 41111,
  'tradingsymbol': 'NIFTY2160314250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10524674,
  'exchange_token': 41112,
  'tradingsymbol': 'NIFTY2160314250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10524930,
  'exchange_token': 41113,
  'tradingsymbol': 'NIFTY2160314300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10525186,
  'exchange_token': 41114,
  'tradingsymbol': 'NIFTY2160314300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10525442,
  'exchange_token': 41115,
  'tradingsymbol': 'NIFTY2160314350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10525698,
  'exchange_token': 41116,
  'tradingsymbol': 'NIFTY2160314350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10525954,
  'exchange_token': 41117,
  'tradingsymbol': 'NIFTY2160314400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10526210,
  'exchange_token': 41118,
  'tradingsymbol': 'NIFTY2160314400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10526466,
  'exchange_token': 41119,
  'tradingsymbol': 'NIFTY2160314450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10526722,
  'exchange_token': 41120,
  'tradingsymbol': 'NIFTY2160314450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10526978,
  'exchange_token': 41121,
  'tradingsymbol': 'NIFTY2160314500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10527234,
  'exchange_token': 41122,
  'tradingsymbol': 'NIFTY2160314500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10527490,
  'exchange_token': 41123,
  'tradingsymbol': 'NIFTY2160314550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10527746,
  'exchange_token': 41124,
  'tradingsymbol': 'NIFTY2160314550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10528002,
  'exchange_token': 41125,
  'tradingsymbol': 'NIFTY2160314600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10528258,
  'exchange_token': 41126,
  'tradingsymbol': 'NIFTY2160314600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10530562,
  'exchange_token': 41135,
  'tradingsymbol': 'NIFTY2160314650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10531074,
  'exchange_token': 41137,
  'tradingsymbol': 'NIFTY2160314650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10532354,
  'exchange_token': 41142,
  'tradingsymbol': 'NIFTY2160314700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10532866,
  'exchange_token': 41144,
  'tradingsymbol': 'NIFTY2160314700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10533122,
  'exchange_token': 41145,
  'tradingsymbol': 'NIFTY2160314750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10533634,
  'exchange_token': 41147,
  'tradingsymbol': 'NIFTY2160314750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10533890,
  'exchange_token': 41148,
  'tradingsymbol': 'NIFTY2160314800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10538498,
  'exchange_token': 41166,
  'tradingsymbol': 'NIFTY2160314800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10540290,
  'exchange_token': 41173,
  'tradingsymbol': 'NIFTY2160314850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10541570,
  'exchange_token': 41178,
  'tradingsymbol': 'NIFTY2160314850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10541826,
  'exchange_token': 41179,
  'tradingsymbol': 'NIFTY2160314900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10542082,
  'exchange_token': 41180,
  'tradingsymbol': 'NIFTY2160314900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10542338,
  'exchange_token': 41181,
  'tradingsymbol': 'NIFTY2160314950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10542594,
  'exchange_token': 41182,
  'tradingsymbol': 'NIFTY2160314950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 14950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10542850,
  'exchange_token': 41183,
  'tradingsymbol': 'NIFTY2160315000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10543106,
  'exchange_token': 41184,
  'tradingsymbol': 'NIFTY2160315000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10543362,
  'exchange_token': 41185,
  'tradingsymbol': 'NIFTY2160315050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10543618,
  'exchange_token': 41186,
  'tradingsymbol': 'NIFTY2160315050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10543874,
  'exchange_token': 41187,
  'tradingsymbol': 'NIFTY2160315100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10544130,
  'exchange_token': 41188,
  'tradingsymbol': 'NIFTY2160315100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10544898,
  'exchange_token': 41191,
  'tradingsymbol': 'NIFTY2160315150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10545154,
  'exchange_token': 41192,
  'tradingsymbol': 'NIFTY2160315150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10545410,
  'exchange_token': 41193,
  'tradingsymbol': 'NIFTY2160315200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10545666,
  'exchange_token': 41194,
  'tradingsymbol': 'NIFTY2160315200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10545922,
  'exchange_token': 41195,
  'tradingsymbol': 'NIFTY2160315250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10546178,
  'exchange_token': 41196,
  'tradingsymbol': 'NIFTY2160315250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10546434,
  'exchange_token': 41197,
  'tradingsymbol': 'NIFTY2160315300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10546690,
  'exchange_token': 41198,
  'tradingsymbol': 'NIFTY2160315300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10549506,
  'exchange_token': 41209,
  'tradingsymbol': 'NIFTY2160315350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10550786,
  'exchange_token': 41214,
  'tradingsymbol': 'NIFTY2160315350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10551042,
  'exchange_token': 41215,
  'tradingsymbol': 'NIFTY2160315400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10551554,
  'exchange_token': 41217,
  'tradingsymbol': 'NIFTY2160315400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15400,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10551810,
  'exchange_token': 41218,
  'tradingsymbol': 'NIFTY2160315450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10553346,
  'exchange_token': 41224,
  'tradingsymbol': 'NIFTY2160315450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15450,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10557186,
  'exchange_token': 41239,
  'tradingsymbol': 'NIFTY2160315500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10557442,
  'exchange_token': 41240,
  'tradingsymbol': 'NIFTY2160315500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15500,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10558210,
  'exchange_token': 41243,
  'tradingsymbol': 'NIFTY2160315550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10560514,
  'exchange_token': 41252,
  'tradingsymbol': 'NIFTY2160315550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15550,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10560770,
  'exchange_token': 41253,
  'tradingsymbol': 'NIFTY2160315600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10561026,
  'exchange_token': 41254,
  'tradingsymbol': 'NIFTY2160315600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15600,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10561282,
  'exchange_token': 41255,
  'tradingsymbol': 'NIFTY2160315650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10561538,
  'exchange_token': 41256,
  'tradingsymbol': 'NIFTY2160315650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15650,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10561794,
  'exchange_token': 41257,
  'tradingsymbol': 'NIFTY2160315700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10562050,
  'exchange_token': 41258,
  'tradingsymbol': 'NIFTY2160315700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15700,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10562306,
  'exchange_token': 41259,
  'tradingsymbol': 'NIFTY2160315750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10562562,
  'exchange_token': 41260,
  'tradingsymbol': 'NIFTY2160315750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15750,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10562818,
  'exchange_token': 41261,
  'tradingsymbol': 'NIFTY2160315800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10565122,
  'exchange_token': 41270,
  'tradingsymbol': 'NIFTY2160315800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15800,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10565378,
  'exchange_token': 41271,
  'tradingsymbol': 'NIFTY2160315850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10565634,
  'exchange_token': 41272,
  'tradingsymbol': 'NIFTY2160315850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15850,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10565890,
  'exchange_token': 41273,
  'tradingsymbol': 'NIFTY2160315900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10566146,
  'exchange_token': 41274,
  'tradingsymbol': 'NIFTY2160315900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15900,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10566402,
  'exchange_token': 41275,
  'tradingsymbol': 'NIFTY2160315950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10566658,
  'exchange_token': 41276,
  'tradingsymbol': 'NIFTY2160315950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 15950,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10566914,
  'exchange_token': 41277,
  'tradingsymbol': 'NIFTY2160316000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10567170,
  'exchange_token': 41278,
  'tradingsymbol': 'NIFTY2160316000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16000,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10567426,
  'exchange_token': 41279,
  'tradingsymbol': 'NIFTY2160316050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10568194,
  'exchange_token': 41282,
  'tradingsymbol': 'NIFTY2160316050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16050,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10568450,
  'exchange_token': 41283,
  'tradingsymbol': 'NIFTY2160316100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10568706,
  'exchange_token': 41284,
  'tradingsymbol': 'NIFTY2160316100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16100,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10573826,
  'exchange_token': 41304,
  'tradingsymbol': 'NIFTY2160316150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10574082,
  'exchange_token': 41305,
  'tradingsymbol': 'NIFTY2160316150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16150,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10574850,
  'exchange_token': 41308,
  'tradingsymbol': 'NIFTY2160316200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10575106,
  'exchange_token': 41309,
  'tradingsymbol': 'NIFTY2160316200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16200,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10576386,
  'exchange_token': 41314,
  'tradingsymbol': 'NIFTY2160316250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10580226,
  'exchange_token': 41329,
  'tradingsymbol': 'NIFTY2160316250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16250,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10580482,
  'exchange_token': 41330,
  'tradingsymbol': 'NIFTY2160316300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10580738,
  'exchange_token': 41331,
  'tradingsymbol': 'NIFTY2160316300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16300,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10580994,
  'exchange_token': 41332,
  'tradingsymbol': 'NIFTY2160316350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10581250,
  'exchange_token': 41333,
  'tradingsymbol': 'NIFTY2160316350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 16350,
  'tick_size': 0.05,
  'lot_size': 75,
  'trailingStopLoss': 0,
  'targetBreak': 2,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12538882,
  'exchange_token': 48980,
  'tradingsymbol': 'BANKNIFTY2160327800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 27800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12539650,
  'exchange_token': 48983,
  'tradingsymbol': 'BANKNIFTY2160327800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 27800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12539906,
  'exchange_token': 48984,
  'tradingsymbol': 'BANKNIFTY2160327900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 27900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12540162,
  'exchange_token': 48985,
  'tradingsymbol': 'BANKNIFTY2160327900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 27900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12540418,
  'exchange_token': 48986,
  'tradingsymbol': 'BANKNIFTY2160328000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12540674,
  'exchange_token': 48987,
  'tradingsymbol': 'BANKNIFTY2160328000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12540930,
  'exchange_token': 48988,
  'tradingsymbol': 'BANKNIFTY2160328100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12541186,
  'exchange_token': 48989,
  'tradingsymbol': 'BANKNIFTY2160328100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12541442,
  'exchange_token': 48990,
  'tradingsymbol': 'BANKNIFTY2160328200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12541698,
  'exchange_token': 48991,
  'tradingsymbol': 'BANKNIFTY2160328200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12541954,
  'exchange_token': 48992,
  'tradingsymbol': 'BANKNIFTY2160328300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12542210,
  'exchange_token': 48993,
  'tradingsymbol': 'BANKNIFTY2160328300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12542466,
  'exchange_token': 48994,
  'tradingsymbol': 'BANKNIFTY2160328400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12542722,
  'exchange_token': 48995,
  'tradingsymbol': 'BANKNIFTY2160328400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12542978,
  'exchange_token': 48996,
  'tradingsymbol': 'BANKNIFTY2160328500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12543234,
  'exchange_token': 48997,
  'tradingsymbol': 'BANKNIFTY2160328500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12543490,
  'exchange_token': 48998,
  'tradingsymbol': 'BANKNIFTY2160328600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12543746,
  'exchange_token': 48999,
  'tradingsymbol': 'BANKNIFTY2160328600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12544002,
  'exchange_token': 49000,
  'tradingsymbol': 'BANKNIFTY2160328700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12544258,
  'exchange_token': 49001,
  'tradingsymbol': 'BANKNIFTY2160328700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12544514,
  'exchange_token': 49002,
  'tradingsymbol': 'BANKNIFTY2160328800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12544770,
  'exchange_token': 49003,
  'tradingsymbol': 'BANKNIFTY2160328800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12545026,
  'exchange_token': 49004,
  'tradingsymbol': 'BANKNIFTY2160328900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12545282,
  'exchange_token': 49005,
  'tradingsymbol': 'BANKNIFTY2160328900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 28900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12545538,
  'exchange_token': 49006,
  'tradingsymbol': 'BANKNIFTY2160329000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12545794,
  'exchange_token': 49007,
  'tradingsymbol': 'BANKNIFTY2160329000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12546050,
  'exchange_token': 49008,
  'tradingsymbol': 'BANKNIFTY2160329100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12546306,
  'exchange_token': 49009,
  'tradingsymbol': 'BANKNIFTY2160329100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12546562,
  'exchange_token': 49010,
  'tradingsymbol': 'BANKNIFTY2160329200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12551426,
  'exchange_token': 49029,
  'tradingsymbol': 'BANKNIFTY2160329200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12551682,
  'exchange_token': 49030,
  'tradingsymbol': 'BANKNIFTY2160329300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12552962,
  'exchange_token': 49035,
  'tradingsymbol': 'BANKNIFTY2160329300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9014274,
  'exchange_token': 35212,
  'tradingsymbol': 'BANKNIFTY2160329400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9014530,
  'exchange_token': 35213,
  'tradingsymbol': 'BANKNIFTY2160329400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11422466,
  'exchange_token': 44619,
  'tradingsymbol': 'BANKNIFTY2160329500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11422978,
  'exchange_token': 44621,
  'tradingsymbol': 'BANKNIFTY2160329500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11423490,
  'exchange_token': 44623,
  'tradingsymbol': 'BANKNIFTY2160329600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11423746,
  'exchange_token': 44624,
  'tradingsymbol': 'BANKNIFTY2160329600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9840642,
  'exchange_token': 38440,
  'tradingsymbol': 'BANKNIFTY2160329700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9841666,
  'exchange_token': 38444,
  'tradingsymbol': 'BANKNIFTY2160329700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9841922,
  'exchange_token': 38445,
  'tradingsymbol': 'BANKNIFTY2160329800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9842178,
  'exchange_token': 38446,
  'tradingsymbol': 'BANKNIFTY2160329800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9842434,
  'exchange_token': 38447,
  'tradingsymbol': 'BANKNIFTY2160329900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9848834,
  'exchange_token': 38472,
  'tradingsymbol': 'BANKNIFTY2160329900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 29900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9849602,
  'exchange_token': 38475,
  'tradingsymbol': 'BANKNIFTY2160330000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9849858,
  'exchange_token': 38476,
  'tradingsymbol': 'BANKNIFTY2160330000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9876738,
  'exchange_token': 38581,
  'tradingsymbol': 'BANKNIFTY2160330100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9876994,
  'exchange_token': 38582,
  'tradingsymbol': 'BANKNIFTY2160330100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9877250,
  'exchange_token': 38583,
  'tradingsymbol': 'BANKNIFTY2160330200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9877762,
  'exchange_token': 38585,
  'tradingsymbol': 'BANKNIFTY2160330200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9878530,
  'exchange_token': 38588,
  'tradingsymbol': 'BANKNIFTY2160330300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9879554,
  'exchange_token': 38592,
  'tradingsymbol': 'BANKNIFTY2160330300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9879810,
  'exchange_token': 38593,
  'tradingsymbol': 'BANKNIFTY2160330400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9880066,
  'exchange_token': 38594,
  'tradingsymbol': 'BANKNIFTY2160330400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9880322,
  'exchange_token': 38595,
  'tradingsymbol': 'BANKNIFTY2160330500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9881346,
  'exchange_token': 38599,
  'tradingsymbol': 'BANKNIFTY2160330500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9881602,
  'exchange_token': 38600,
  'tradingsymbol': 'BANKNIFTY2160330600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9881858,
  'exchange_token': 38601,
  'tradingsymbol': 'BANKNIFTY2160330600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9883138,
  'exchange_token': 38606,
  'tradingsymbol': 'BANKNIFTY2160330700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9884674,
  'exchange_token': 38612,
  'tradingsymbol': 'BANKNIFTY2160330700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9884930,
  'exchange_token': 38613,
  'tradingsymbol': 'BANKNIFTY2160330800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9885954,
  'exchange_token': 38617,
  'tradingsymbol': 'BANKNIFTY2160330800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10329602,
  'exchange_token': 40350,
  'tradingsymbol': 'BANKNIFTY2160330900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10329858,
  'exchange_token': 40351,
  'tradingsymbol': 'BANKNIFTY2160330900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 30900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10330114,
  'exchange_token': 40352,
  'tradingsymbol': 'BANKNIFTY2160331000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10330370,
  'exchange_token': 40353,
  'tradingsymbol': 'BANKNIFTY2160331000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10330626,
  'exchange_token': 40354,
  'tradingsymbol': 'BANKNIFTY2160331100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10330882,
  'exchange_token': 40355,
  'tradingsymbol': 'BANKNIFTY2160331100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10331138,
  'exchange_token': 40356,
  'tradingsymbol': 'BANKNIFTY2160331200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10331394,
  'exchange_token': 40357,
  'tradingsymbol': 'BANKNIFTY2160331200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10331650,
  'exchange_token': 40358,
  'tradingsymbol': 'BANKNIFTY2160331300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10332418,
  'exchange_token': 40361,
  'tradingsymbol': 'BANKNIFTY2160331300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10332674,
  'exchange_token': 40362,
  'tradingsymbol': 'BANKNIFTY2160331400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10332930,
  'exchange_token': 40363,
  'tradingsymbol': 'BANKNIFTY2160331400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10333186,
  'exchange_token': 40364,
  'tradingsymbol': 'BANKNIFTY2160331500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10333442,
  'exchange_token': 40365,
  'tradingsymbol': 'BANKNIFTY2160331500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10333698,
  'exchange_token': 40366,
  'tradingsymbol': 'BANKNIFTY2160331600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10333954,
  'exchange_token': 40367,
  'tradingsymbol': 'BANKNIFTY2160331600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10337026,
  'exchange_token': 40379,
  'tradingsymbol': 'BANKNIFTY2160331700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10337794,
  'exchange_token': 40382,
  'tradingsymbol': 'BANKNIFTY2160331700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10338050,
  'exchange_token': 40383,
  'tradingsymbol': 'BANKNIFTY2160331800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10338306,
  'exchange_token': 40384,
  'tradingsymbol': 'BANKNIFTY2160331800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10340610,
  'exchange_token': 40393,
  'tradingsymbol': 'BANKNIFTY2160331900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10340866,
  'exchange_token': 40394,
  'tradingsymbol': 'BANKNIFTY2160331900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 31900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10341122,
  'exchange_token': 40395,
  'tradingsymbol': 'BANKNIFTY2160332000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10341378,
  'exchange_token': 40396,
  'tradingsymbol': 'BANKNIFTY2160332000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10342146,
  'exchange_token': 40399,
  'tradingsymbol': 'BANKNIFTY2160332100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10342402,
  'exchange_token': 40400,
  'tradingsymbol': 'BANKNIFTY2160332100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10343170,
  'exchange_token': 40403,
  'tradingsymbol': 'BANKNIFTY2160332200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10343426,
  'exchange_token': 40404,
  'tradingsymbol': 'BANKNIFTY2160332200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10343682,
  'exchange_token': 40405,
  'tradingsymbol': 'BANKNIFTY2160332300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10344194,
  'exchange_token': 40407,
  'tradingsymbol': 'BANKNIFTY2160332300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10348802,
  'exchange_token': 40425,
  'tradingsymbol': 'BANKNIFTY2160332400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10349314,
  'exchange_token': 40427,
  'tradingsymbol': 'BANKNIFTY2160332400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10350082,
  'exchange_token': 40430,
  'tradingsymbol': 'BANKNIFTY2160332500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10350338,
  'exchange_token': 40431,
  'tradingsymbol': 'BANKNIFTY2160332500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10350594,
  'exchange_token': 40432,
  'tradingsymbol': 'BANKNIFTY2160332600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10350850,
  'exchange_token': 40433,
  'tradingsymbol': 'BANKNIFTY2160332600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10351106,
  'exchange_token': 40434,
  'tradingsymbol': 'BANKNIFTY2160332700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10351362,
  'exchange_token': 40435,
  'tradingsymbol': 'BANKNIFTY2160332700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10353410,
  'exchange_token': 40443,
  'tradingsymbol': 'BANKNIFTY2160332800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10353666,
  'exchange_token': 40444,
  'tradingsymbol': 'BANKNIFTY2160332800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10353922,
  'exchange_token': 40445,
  'tradingsymbol': 'BANKNIFTY2160332900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10354178,
  'exchange_token': 40446,
  'tradingsymbol': 'BANKNIFTY2160332900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 32900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10354434,
  'exchange_token': 40447,
  'tradingsymbol': 'BANKNIFTY2160333000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10354690,
  'exchange_token': 40448,
  'tradingsymbol': 'BANKNIFTY2160333000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10357506,
  'exchange_token': 40459,
  'tradingsymbol': 'BANKNIFTY2160333100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10357762,
  'exchange_token': 40460,
  'tradingsymbol': 'BANKNIFTY2160333100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10359554,
  'exchange_token': 40467,
  'tradingsymbol': 'BANKNIFTY2160333200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10361858,
  'exchange_token': 40476,
  'tradingsymbol': 'BANKNIFTY2160333200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10362882,
  'exchange_token': 40480,
  'tradingsymbol': 'BANKNIFTY2160333300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10363138,
  'exchange_token': 40481,
  'tradingsymbol': 'BANKNIFTY2160333300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10363394,
  'exchange_token': 40482,
  'tradingsymbol': 'BANKNIFTY2160333400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10363906,
  'exchange_token': 40484,
  'tradingsymbol': 'BANKNIFTY2160333400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10364162,
  'exchange_token': 40485,
  'tradingsymbol': 'BANKNIFTY2160333500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10364418,
  'exchange_token': 40486,
  'tradingsymbol': 'BANKNIFTY2160333500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10366466,
  'exchange_token': 40494,
  'tradingsymbol': 'BANKNIFTY2160333600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10371842,
  'exchange_token': 40515,
  'tradingsymbol': 'BANKNIFTY2160333600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10372354,
  'exchange_token': 40517,
  'tradingsymbol': 'BANKNIFTY2160333700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10372610,
  'exchange_token': 40518,
  'tradingsymbol': 'BANKNIFTY2160333700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10376962,
  'exchange_token': 40535,
  'tradingsymbol': 'BANKNIFTY2160333800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10377218,
  'exchange_token': 40536,
  'tradingsymbol': 'BANKNIFTY2160333800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10377474,
  'exchange_token': 40537,
  'tradingsymbol': 'BANKNIFTY2160333900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10377730,
  'exchange_token': 40538,
  'tradingsymbol': 'BANKNIFTY2160333900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 33900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10379010,
  'exchange_token': 40543,
  'tradingsymbol': 'BANKNIFTY2160334000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10379266,
  'exchange_token': 40544,
  'tradingsymbol': 'BANKNIFTY2160334000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10380546,
  'exchange_token': 40549,
  'tradingsymbol': 'BANKNIFTY2160334100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10381314,
  'exchange_token': 40552,
  'tradingsymbol': 'BANKNIFTY2160334100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10383362,
  'exchange_token': 40560,
  'tradingsymbol': 'BANKNIFTY2160334200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10383618,
  'exchange_token': 40561,
  'tradingsymbol': 'BANKNIFTY2160334200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10384898,
  'exchange_token': 40566,
  'tradingsymbol': 'BANKNIFTY2160334300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10385154,
  'exchange_token': 40567,
  'tradingsymbol': 'BANKNIFTY2160334300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10385666,
  'exchange_token': 40569,
  'tradingsymbol': 'BANKNIFTY2160334400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10388226,
  'exchange_token': 40579,
  'tradingsymbol': 'BANKNIFTY2160334400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10388994,
  'exchange_token': 40582,
  'tradingsymbol': 'BANKNIFTY2160334500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10389762,
  'exchange_token': 40585,
  'tradingsymbol': 'BANKNIFTY2160334500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10390018,
  'exchange_token': 40586,
  'tradingsymbol': 'BANKNIFTY2160334600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10390274,
  'exchange_token': 40587,
  'tradingsymbol': 'BANKNIFTY2160334600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10390530,
  'exchange_token': 40588,
  'tradingsymbol': 'BANKNIFTY2160334700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10390786,
  'exchange_token': 40589,
  'tradingsymbol': 'BANKNIFTY2160334700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10391042,
  'exchange_token': 40590,
  'tradingsymbol': 'BANKNIFTY2160334800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10392066,
  'exchange_token': 40594,
  'tradingsymbol': 'BANKNIFTY2160334800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10393090,
  'exchange_token': 40598,
  'tradingsymbol': 'BANKNIFTY2160334900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10393346,
  'exchange_token': 40599,
  'tradingsymbol': 'BANKNIFTY2160334900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 34900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10393602,
  'exchange_token': 40600,
  'tradingsymbol': 'BANKNIFTY2160335000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10393858,
  'exchange_token': 40601,
  'tradingsymbol': 'BANKNIFTY2160335000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10394114,
  'exchange_token': 40602,
  'tradingsymbol': 'BANKNIFTY2160335100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10394882,
  'exchange_token': 40605,
  'tradingsymbol': 'BANKNIFTY2160335100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10395138,
  'exchange_token': 40606,
  'tradingsymbol': 'BANKNIFTY2160335200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10395394,
  'exchange_token': 40607,
  'tradingsymbol': 'BANKNIFTY2160335200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10395650,
  'exchange_token': 40608,
  'tradingsymbol': 'BANKNIFTY2160335300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10395906,
  'exchange_token': 40609,
  'tradingsymbol': 'BANKNIFTY2160335300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10396162,
  'exchange_token': 40610,
  'tradingsymbol': 'BANKNIFTY2160335400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10396418,
  'exchange_token': 40611,
  'tradingsymbol': 'BANKNIFTY2160335400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10396674,
  'exchange_token': 40612,
  'tradingsymbol': 'BANKNIFTY2160335500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10396930,
  'exchange_token': 40613,
  'tradingsymbol': 'BANKNIFTY2160335500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10399490,
  'exchange_token': 40623,
  'tradingsymbol': 'BANKNIFTY2160335600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10399746,
  'exchange_token': 40624,
  'tradingsymbol': 'BANKNIFTY2160335600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10400002,
  'exchange_token': 40625,
  'tradingsymbol': 'BANKNIFTY2160335700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10400258,
  'exchange_token': 40626,
  'tradingsymbol': 'BANKNIFTY2160335700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10402050,
  'exchange_token': 40633,
  'tradingsymbol': 'BANKNIFTY2160335800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10402306,
  'exchange_token': 40634,
  'tradingsymbol': 'BANKNIFTY2160335800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10402562,
  'exchange_token': 40635,
  'tradingsymbol': 'BANKNIFTY2160335900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10403842,
  'exchange_token': 40640,
  'tradingsymbol': 'BANKNIFTY2160335900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 35900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10404098,
  'exchange_token': 40641,
  'tradingsymbol': 'BANKNIFTY2160336000CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10404354,
  'exchange_token': 40642,
  'tradingsymbol': 'BANKNIFTY2160336000PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36000,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10404610,
  'exchange_token': 40643,
  'tradingsymbol': 'BANKNIFTY2160336100CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10404866,
  'exchange_token': 40644,
  'tradingsymbol': 'BANKNIFTY2160336100PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36100,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10407682,
  'exchange_token': 40655,
  'tradingsymbol': 'BANKNIFTY2160336200CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10408962,
  'exchange_token': 40660,
  'tradingsymbol': 'BANKNIFTY2160336200PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36200,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10409218,
  'exchange_token': 40661,
  'tradingsymbol': 'BANKNIFTY2160336300CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10409474,
  'exchange_token': 40662,
  'tradingsymbol': 'BANKNIFTY2160336300PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36300,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10411266,
  'exchange_token': 40669,
  'tradingsymbol': 'BANKNIFTY2160336400CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10412034,
  'exchange_token': 40672,
  'tradingsymbol': 'BANKNIFTY2160336400PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36400,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10417410,
  'exchange_token': 40693,
  'tradingsymbol': 'BANKNIFTY2160336500CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10417666,
  'exchange_token': 40694,
  'tradingsymbol': 'BANKNIFTY2160336500PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36500,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10417922,
  'exchange_token': 40695,
  'tradingsymbol': 'BANKNIFTY2160336600CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10418434,
  'exchange_token': 40697,
  'tradingsymbol': 'BANKNIFTY2160336600PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36600,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10418690,
  'exchange_token': 40698,
  'tradingsymbol': 'BANKNIFTY2160336700CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10418946,
  'exchange_token': 40699,
  'tradingsymbol': 'BANKNIFTY2160336700PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36700,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10419202,
  'exchange_token': 40700,
  'tradingsymbol': 'BANKNIFTY2160336800CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10419458,
  'exchange_token': 40701,
  'tradingsymbol': 'BANKNIFTY2160336800PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36800,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10419714,
  'exchange_token': 40702,
  'tradingsymbol': 'BANKNIFTY2160336900CE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10419970,
  'exchange_token': 40703,
  'tradingsymbol': 'BANKNIFTY2160336900PE',
  'name': 'BANKNIFTY',
  'last_price': 0,
  'expiry': '03-06-2021',
  'strike': 36900,
  'tick_size': 0.05,
  'lot_size': 25,
  'trailingStopLoss': 20,
  'targetBreak': 5,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 }
];
