import React from 'react';
import { useSelector } from 'react-redux';

import {
 Chart,
 Interval,
 Legend,
 Axis
} from 'bizcharts';
import { getPerformanceChartData } from 'redux/modules/trading/selectors';

const scale = {
 'count': { 'max': 50,
  'tickInterval': 5 }
};

const PerformanceChart = () => {
 const chartData = useSelector(getPerformanceChartData);

 return (
  <Chart height={400} data={chartData} scale={scale} autoFit>
   {/* <Coord transpose /> */}
   <Legend
    name="name" marker={{
     'symbol': 'circle'

    }} />
   <Interval
    adjust={[
     {
      'type': 'stack'
     }
    ]}
    color={['name', ['#DD5546', '#61D9AA']]}
    // color="name"
    position="range*count"
    // label="count"
    label={['count',
     { 'style': { 'fill': '#FFF' } } // GeometryLabelCfg
    ]}/>
   <Axis
    name="range" label={{ 'style': {
     'textAlign': 'center', // 文本对齐方向，可取值为： start center end
     'fill': '#EFEFED', // 文本的颜色
     // fontSize: '12px', // 文本大小
     // fontWeight: 'bold', // 文本粗细
     'textBaseline': 'top' // 文本基准线，可取 top middle bottom，默认为middle
    } }}/>

  </Chart>
 );
};

export default PerformanceChart;
