
import { KiteConnect } from 'kiteconnect';
import cloudApi from './cloudApi';

const TradingService = (() => {
 let accessToken;

 let apiKey;

 let apiSecret;

 let kiteConnect;

 const setAccessToken = (token) => {
  accessToken = token;
 };

 const setCredentials = (credentials) => {
  apiKey = credentials.apiKey;
  apiSecret = credentials.apiSecret;
  accessToken = credentials.accessToken;

  kiteConnect = new KiteConnect({
   'api_key': apiKey
  });
 };

 const generateSession = async (requestToken) => {
  const accessToken = await cloudApi.generateAccessToken(requestToken);

  return accessToken;
 };

 const init = async (requestToken) => await generateSession(requestToken);

 const hasAccessToken = () => !!accessToken;

 const loginUrl = () => 'https://kite.zerodha.com/connect/login?api_key=' + apiKey;

 return {
  init,
  setCredentials,
  setAccessToken,
  hasAccessToken,
  loginUrl,
  generateSession
 };
})();

export default TradingService;
